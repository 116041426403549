





























import { Component, Vue, Watch } from 'vue-property-decorator'
import * as pbi from "powerbi-client";
import { ReportsService } from '@/services/ReportsService';
import FButton from '@/components/Button/FButton.vue';
import FSelect from '@/components/Form/FSelect.vue';
import { ProfileService } from '@/services/ProfileService';
import { BusyService } from '@/services/BusyService';
import Alert from '@/components/Alert/Alert.vue'


@Component({
  components: {
    Alert,
    FButton,
    FSelect
  },
  metaInfo: () => ReportsService.metaInfo
})
export default class Report extends Vue {
  store = ReportsService;
  cannotFindError = false;


  get reportId(){
    return this.$route.params.reportId;
  }

  get profile() {
    return ProfileService.profile;
  }

  get authorizedUser(){
    return this.profile?.featureToggleDashboard && this.profile?.featureToggleRankingReports || false;
  }

  get isFullScreen() {
    return this.store.isFullScreen;
  }

  toggleFullScreen() {
    this.store.toggleFullScreen();
  }

  onSelectInput(){
    this.$router.push(`/reports/${this.store.currentReportId}`)
  }


  embedPowerBI(){
    this.cannotFindError = false;
    if(!this.earningsDashboard && this.reportId === this.store.earningsReportId){
      this.$router.push(`/reports`);
      return;
    }
    ReportsService.setCurrentReportId(this.reportId);
    const embedReport = this.store.embedReport;
    const dashboardContainer = this.$refs.container as HTMLElement;


    if (!this.store.availableReports || !this.store.reportData || !embedReport){
      this.cannotFindError = true;
      return;
    }

   const config = {
     type: 'report',
     tokenType: pbi.models.TokenType.Embed,
     accessToken: this.store.reportData.embedToken.token,
     embedUrl: embedReport.embedUrl,
     id: this.reportId,
     pageView: 'fitToWidth',
     permissions: pbi.models.Permissions.All,
     settings:{
      navContentPaneEnabled: false,
      bars: {
            statusBar: {
                visible: true
            }
        },
      panes: {
            pageNavigation: {
                visible: this.earningsDashboard
            },
            filters: {
                visible: false
            }
      }
     }
   };

   let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
   powerbi.reset(dashboardContainer);
   const dashboard = powerbi.embed(dashboardContainer, config);

   dashboard.off("loaded");
   dashboard.off("rendered");
   dashboard.on("error", () =>{
     dashboard.off("error");
   });
  }

  @Watch('reportId')
  async initReport(){
    BusyService.showBusy();
    await this.store.getEmbedInfo();
    this.embedPowerBI();
    BusyService.hideBusy();
  }

  updateIsFullScreen(){
    if(document.fullscreenElement === null){
      this.store.resetFullScreen();
    }
  }

  get earningsDashboard() {
    return this.$route.fullPath.includes('/earnings-dashboard');
  }

  async mounted () {
    document.addEventListener('fullscreenchange', this.updateIsFullScreen);
    if(!ProfileService.profile?.featureToggleDashboard){
      if(!ProfileService.profile?.featureToggleDashboard){
        this.$router.push(`/NotFound`)
      }
      else if(!ProfileService.profile?.featureToggleRankingReports && !this.earningsDashboard) {
        this.$router.push(`/unauthorized`)
      }
      else if(!ProfileService.profile?.featureToggleRoyalty && this.earningsDashboard) {
        this.$router.push(`/unauthorized`)
      }
      return;
    }

    if(this.earningsDashboard){
      if(ProfileService.profile?.featureToggleRoyalty){
        this.$router.push(`/earnings-dashboard/${this.store.earningsReportId}`)
      } else {
        this.$router.push(`/unauthorized`)
        return;
      }
    }

    if(!this.earningsDashboard && !ProfileService.profile?.featureToggleRankingReports){
      this.$router.push(`/unauthorized`)
      return;
    }

    if(this.earningsDashboard && this.reportId !== this.store.earningsReportId){
      this.$router.push(`/earnings-dashboard`);
      return;
    }
    this.initReport();
  }
}
